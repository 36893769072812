@import "bootstrap/dist/css/bootstrap.css";
html {
    background-color: transparent;
}

body {
    background-color: #fff;
    color: #000;
    font-family: "Avenir Next", sans-serif;
}

// Base
@import "base/base";

// Pages
@import "pages/error";
@import "pages/home_page";
@import "pages/services";
@import "pages/company";
@import "pages/login";
@import "pages/map";
@import "pages/synileiki";
@import "pages/um-okkur";
@import "pages/vottanir";
@import "pages/leidin_ad_laufinu";
@import "pages/front_page";

// Mitt
@import "pages/mitt/simsvorun.scss";
@import "pages/mitt/skraning.scss";
@import "pages/mitt/svar.scss";
@import "pages/mitt/simkerfi/simsvorun.scss";
@import "pages/mitt/thjodskra.scss";
@import "pages/mitt/innra_layout.scss";

// Components
@import "components/clickable_pill";
@import "components/page";
@import "components/side-menu";
@import "components/side_bar";
@import "components/company_info";
@import "components/footer";
@import "components/close_button";
@import "components/reset_verify";
@import "components/video_preview";
@import "components/search_results_cell";
@import "components/item_summary";
@import "components/horizontal_ad";
