@import "../../styles/base/colors.scss";
@import "../../styles/base/variables.scss";

.container {
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
}

.leaf {
    height: 35px;
    width: 35px;
    display: none;
    &.small {
        display: block;
    }
}

.laufid {
    width: 125px;
    margin: 20px 0px;
    color: $primary-color;
    &.small {
        display: none;
    }
}

@media (max-width: 550px) {
    .laufid {
        display: none;
    }
    .leaf {
        display: block;
    }
}
