.search-result-cell-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    & a {
        flex-grow: 1;
        display: flex;
    }
    & .search-result-cell-image-container {
        flex-direction: column;
    }
    & .search-result-cell-image {
        object-fit: contain;
        margin-right: 20px;
        background-color: white;
        border-radius: 10px;

        &.placeholder-icon {
            width: 75px;
            height: 75px;
            padding: 5px;
            color: gray;
            border-radius: 0px;
        }
    }
    & .gold-leaf-award-year {
        width: 75px;
        align-self: flex-end;
        font-weight: bold;
        font-size: 12px;
        line-height: 13px;
        margin-top: 3px;
        flex: 1;
        margin-left: -20px;
    }
    & .laufid-leaf.gold-leaf {
        margin-top: 5px;
        max-height: 45px;
        color: gold;
    }

    .search-result-cell-info-name {
        font-size: 20px;
        font-weight: 600;
        margin: 0px;
        margin-right: 10px;
        margin-bottom: 3px;
    }

    .search-result-cell-info-job {
        font-size: 14px;
        text-align: center;
        justify-content: center;
        align-self: center;
    }
    .search-result-cell-leaves-container {
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;
        & .laufid-leaf {
            color: white;
            height: 30px;
            margin-right: 10px;
        }
    }
    .green-step-icon {
        color: white;
        transition: 0.1s ease-in-out;
    }
}

.search-result-cell-container:hover {
    .laufid-leaf {
        color: #f9f9f9;
        transition: 0.1s ease-in-out;
    }
    .green-step-icon {
        color: #f9f9f9;
        transition: 0.1s ease-in-out;
    }
    background-color: #f9f9f9;
    transition: 0.1s ease-in-out;
}
