@import "../../../styles/base/colors.scss";
@import "../../../styles/base/variables.scss";

.container {
    z-index: 100;
    display: flex;
    flex-direction: column;
    background-color: $primary-color;
    color: $white;

    h5 {
        color: $white;
    }

    nav {
        padding-top: 50px;
        padding-bottom: 35px;
        display: flex;

        width: 100%;
        align-self: center;
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width: 550px) {
            flex-direction: column;
        }
        div {
            flex-grow: 1;
        }
        h5 {
            margin-bottom: 20px;
            font-weight: 600;
        }
        ul {
            list-style-type: none; /* Remove bullets */
            padding: 0;
            li {
                color: $white;
                padding: 5px 0px;
            }
        }
    }
}

.copyright {
    color: $white;
    font-style: normal;
    font-weight: 400;

    margin: 0;
}

.contentContainer {
    max-width: $max-content-width;
    flex-grow: 1;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: $white;
}

.footerBottom {
    max-width: $max-content-width;
    width: 100%;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.socialContainer {
    display: flex;
    gap: 10px;
    align-self: flex-end;
}

.socialIcon {
    height: 30px;
    width: 30px;
}
