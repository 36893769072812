.page {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .page-content {
        flex: 1;
        padding-top: 65px;
        scroll-padding-top: 65px;

        .page-body {
            padding-top: 15px;
            padding-bottom: 80px;
        }
    }
}
