@import "../../../styles/base/colors.scss";
@import "../../../styles/base/variables.scss";

.container {
    // background-color: #f3f3f3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
    overflow-x: auto;

    background: rgba(243, 243, 243, 0.9);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);

    .logo-container {
        height: 65px;
        width: 50px;
        padding-top: 10px;
        padding-bottom: 9px;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menuItemText {
        margin: 0 7px;
        padding: 5px 15px;
        text-align: center;
        vertical-align: center;
        border-radius: 5px;
        h5 {
            margin: 0;
            color: $primary-color;
            font-weight: 500;
            color: $primary-color;
            font-size: 1em;
        }
        &.inverted {
            background-color: $primary-color;
            h5 {
                color: white;
            }
        }
    }

    nav {
        display: flex;
    }
}

.contentContainer {
    display: flex;
}

.headerAccessories {
    display: flex;

    @media screen and (max-width: 900px) {
        display: none !important;
    }
}

.loginButton .showsSearchBar {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}

.tryFreeButton .showsSearchBar {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}

.showsSearchBar {
    @media screen and (max-width: 600px) {
        display: none !important;
    }
}
